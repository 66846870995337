import Helmet from 'preact-helmet';
import RightPanel from '../../components/right';

const Ceny = () => (
	<div>
		<Helmet title="Cenová nabídka" />
		<div class="first_container">
			<div class="story">
				<div class="breadcrumb">
					<h1>Cenová nabídka</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Co potřebujeme k vytvoření cenové nabídky?</li>
					</ul>
				</div>
				<h2>Co potřebujeme k vytvoření cenové nabídky?</h2>
				<p>&nbsp;</p>
				<ul>
					<li>Balený produkt - SYPKÝ, TEKUTÝ, KUSOVÉ ZBOŽÍ (cokoliv, co potřebujete zabalit.)</li>
					<li>objem plnení produktu (Hmotnost-gr, Objem-ml, množství-ks)</li>
					<li>požadavky produktů na obalový materiál</li>
					<li>typ baleni nebo sáčku, plochy (hadicový nebo stickový)</li>
					<li>velikost baleni</li>
					<li>informace o šarži, datum spotřeby, čárový kód, logo, texty…</li>
					<li>počet barev potisku (grafku nejlépe ve formátu PDF)</li>
					<li>množství sáčků, jednorázové množství, vícnásobná nebo celoroční výroba), neznáte-li všechny údaje, rádi se vám ozveme a poradíme</li>
				</ul>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default Ceny;